import React, { useState, useEffect } from 'react'
import dotenv from 'dotenv'
import { ethers } from 'ethers'
import { initWeb3Onboard } from './services'
import {
  useConnectWallet,
  useSetChain,
  useWallets
  // useNotifications, //if using notification in app.js
} from '@web3-onboard/react'
import * as alchemyConst from './services'
// old FastLane Relay SC
import contractABI from './abi/FastLaneRelayAbi.json'
// new FastLane Auction SC with Kairos Auction
import kairosUpdateAbi from './abi/KairosUpdateAbi.json'

//components
import Navbar from './components/Layout/Navbar/Navbar'
import Footer from './components/Layout/Footer/Footer'
import ConnectedWalletInfo from './components/Layout/StatsAndInteraction/ConnectedWalletInfo'
import HalfCircle from './assets/half-circle.svg'
// import TxMenu from './components/TxMenu'
import CallContract from './components/Layout/StatsAndInteraction/CallContract'
import ValidatorSettings from './components/QueryComponents/ApolloComponents/ValidatorSettings'

// utils
import { truncateDecimal } from './components/utils/utils'

dotenv.config()
let provider, smartContract, smartContractV2
const CONTRACT_ADR = process.env.REACT_APP_CONTRACT_ADDRESS
const CONTRACT_ADR_V2 = process.env.REACT_APP_CONTRACT_ADDRESS_V2

const App = () => {
  const [{ wallet }, connect, disconnect] = useConnectWallet()
  // const [{ connectedChain }] = useSetChain()
  const [{ connectedChain }] = useSetChain()
  const connectedWallets = useWallets()
  const [web3Onboard, setWeb3Onboard] = useState(null)
  // activeWalletAddress is the
  var [activeWalletAddress, setActiveWalletAddress] = useState('')
  var [userInputAddress, setInputAddress] = useState('')
  // Smart Contract Variables
  var [activeAddressValidatorStatus, setActiveAddressStatus] = useState(false)
  var [connectedAddressValidatorStatus, setConnectedAddressStatus] =
    useState(false)
  var [payeeSCv1, setPayeeSCv1] = useState(
    '0x0000000000000000000000000000000000000000'
  )
  var [payeeSCv2, setPayeeSCv2] = useState(
    '0x0000000000000000000000000000000000000000'
  )
  var [withdrawableAmountMaticSCv1, setWithdrawableAmountMaticSCv1] =
    useState(0)
  var [withdrawableAmountGweiSCv1, setWithdrawableAmountGweiSCv1] = useState(0)
  var [withdrawableAmountMaticSCv2, setWithdrawableAmountMaticSCv2] =
    useState(0)
  var [withdrawableAmountGweiSCv2, setWithdrawableAmountGweiSCv2] = useState(0)

  // const [notifications] = useNotifications()

  // set shortened wallet address; example: 0x123...999
  var connectedAddress = wallet && wallet.accounts[0].address
  var addressArray = wallet && connectedAddress.split('')
  var shortenedAddress =
    wallet &&
    addressArray.slice(0, 6).join('') +
      '...' +
      addressArray.slice(39, 42).join('')

  useEffect(() => {
    setWeb3Onboard(initWeb3Onboard)
  }, [])

  useEffect(() => {
    if (!wallet?.provider) {
      provider = null
    } else {
      //on new wallet connect:
      provider = new ethers.providers.Web3Provider(wallet.provider, 'any')
      // connectedAddress && alchemyConst.alchemy.core.getBlockNumber()
      connectedAddress &&
        alchemyConst.alchemy.core.getTokenBalances(connectedAddress)
      //establish smart contract
      smartContract = new ethers.Contract(
        CONTRACT_ADR,
        contractABI,
        provider.getUncheckedSigner()
      )
      smartContractV2 = new ethers.Contract(
        CONTRACT_ADR_V2,
        kairosUpdateAbi,
        provider.getUncheckedSigner()
      )
      //initially, set the "active" address to the currently CONNECTED wallet
      setActiveWalletAddress(connectedAddress.toLowerCase())
    }
  }, [wallet, connectedAddress])

  // if user inputs an address, then set the "active" wallet address to the USER INPUTTED address
  useEffect(() => {
    setActiveWalletAddress(userInputAddress.toLowerCase())
  }, [userInputAddress])

  // Check if active wallet (user inputted adr) and the connected wallet address are a validator
  // in old smart contract
  async function isValidator(activeWalletAddress) {
    try {
      const isActiveAddressValidator =
        activeWalletAddress &&
        (await smartContract.getValidatorStatus(activeWalletAddress))
      const isConnectedAddressValidator =
        await smartContract.getValidatorStatus(connectedAddress)

      isActiveAddressValidator
        ? setActiveAddressStatus(true)
        : setActiveAddressStatus(false)
      isConnectedAddressValidator
        ? setConnectedAddressStatus(true)
        : setConnectedAddressStatus(false)
    } catch (err) {
      // console.log(`error connecting to smart contract - `, err)
    }
  }
  isValidator(activeWalletAddress)

  // request data from smart contract using current wallet address
  async function CallSmartContract(activeWalletAddress) {
    try {
      //gets validator's payee; Used to display payee address on hover of "Withdraw" button on old smart contract
      const getPayeeSCv1 = await smartContract.getValidatorRecipient(
        activeWalletAddress
      )
      //gets pending balance on old SC; displayed
      const getPendingBalance = await smartContract.getValidatorBalance(
        activeWalletAddress
      )

      // const getValidatorStatus = await smartContract.getValidatorStatus(

      // sets variables from data or calls functions when promised is received
      getPayeeSCv1 && setPayeeSCv1(getPayeeSCv1)

      setWithdrawableAmountMaticSCv1(
        truncateDecimal(ethers.utils.formatUnits(getPendingBalance, 18))
      )
      setWithdrawableAmountGweiSCv1(
        Math.round(ethers.utils.formatUnits(getPendingBalance, 9))
      )
    } catch (err) {
      // console.log(`error connecting to smart contract - `, err)
    }
  }
  CallSmartContract(activeWalletAddress)

  async function CallSmartContractV2(activeWalletAddress) {
    try {
      //gets validator's payee; Used to display payee address on hover of "Withdraw" button for KairosUpdate withdraw
      const getPayeeSCv2 = await smartContractV2.getValidatorRecipient(
        activeWalletAddress
      )
      //gets pending balance on KairosUpdate SC; displayed
      const getPendingBalanceSCv2 = await smartContractV2.getValidatorBalance(
        activeWalletAddress
      )

      // get payee from KairosUpdate SC (SCv2)
      getPayeeSCv2 && setPayeeSCv2(getPayeeSCv2)

      setWithdrawableAmountMaticSCv2(
        truncateDecimal(ethers.utils.formatUnits(getPendingBalanceSCv2, 18))
      )
      setWithdrawableAmountGweiSCv2(
        Math.round(ethers.utils.formatUnits(getPendingBalanceSCv2, 9))
      )
    } catch (err) {
      // console.log(`error connecting to smart contract - `, err)
    }
  }
  CallSmartContractV2(activeWalletAddress)

  async function updateValidatorPayeeSCv1(newPayee) {
    try {
      // Assuming smartContract.updateValidatorPayee returns a promise
      await smartContract.updateValidatorPayee(activeWalletAddress, newPayee)
      console.log('(SC v1) - Requesting changing validator payee to:', newPayee)

      setPayeeSCv1(newPayee)
      document.getElementById('currentPayeeDisplay').innerHTML =
        newPayee.slice(0, 7) + '...'
      document.getElementById('currentPayeeDisplay').style.display = 'flex'
      document.getElementById('newPayee').style.display = 'none'
      document.getElementById('checkmarkIcon').style.display = 'none'
      document.getElementById('settingsIcon').style.display = 'flex'

      // Return success status
      return true
    } catch (error) {
      console.error('Error updating validator payee in SC v1:', error)

      // Return error status
      return false
    }
  }

  async function updateValidatorPayeeSCv2(newPayee) {
    try {
      // Assuming smartContractV2.updateValidatorPayee returns a promise
      await smartContractV2.updateValidatorPayee(newPayee)
      console.log('(SC v2) - Requesting changing validator payee to:', newPayee)

      setPayeeSCv2(newPayee)
      // Additional UI updates if necessary

      // Return success status
      return true
    } catch (error) {
      console.error('Error updating validator payee in SC v2:', error)

      // Return error status
      return false
    }
  }

  useEffect(() => {
    if (!connectedWallets.length) return
    const connectedWalletsLabelArray = connectedWallets.map(
      ({ label }) => label
    )
    window.localStorage.setItem(
      'connectedWallets',
      JSON.stringify(connectedWalletsLabelArray)
    )

    // Check for Magic Wallet user session
    if (connectedWalletsLabelArray.includes('Magic Wallet')) {
      const [magicWalletProvider] = connectedWallets.filter(
        provider => provider.label === 'Magic Wallet'
      )
      async function setMagicUser() {
        try {
          const { email } =
            await magicWalletProvider.instance.user.getMetadata()
          const magicUserEmail = localStorage.getItem('magicUserEmail')
          if (!magicUserEmail || magicUserEmail !== email)
            localStorage.setItem('magicUserEmail', email)
        } catch (err) {
          throw err
        }
      }
      setMagicUser()
    }
  }, [connectedWallets, wallet])

  // auto connect previously connected wallet
  useEffect(() => {
    const previouslyConnectedWallets = JSON.parse(
      window.localStorage.getItem('connectedWallets')
    )

    if (previouslyConnectedWallets?.length) {
      async function setWalletFromLocalStorage() {
        await connect({ autoSelect: previouslyConnectedWallets[0] })
      }
      setWalletFromLocalStorage()
    }
  }, [web3Onboard, connect])

  if (!web3Onboard) return <div>Loading...</div>

  return (
    <main className="text-white w-full block min-h-screen">
      <section className=" w-full block bg-white h-auto min-h-screen">
        <Navbar />
        <div className="skewed-top-left bg-white">
          <div className="skew skew-top ml-for-radius pt-6">
            <svg
              className="h-8 md:h-12 lg:h-10 w-full text-purple-1"
              viewBox="0 0 10 10"
              preserveAspectRatio="none"
            >
              <polygon fill="currentColor" points="0 10 10 0 10 10" />
            </svg>
          </div>
        </div>
        <div className="px-5 md:px-0 py-2 block h-full w-full bg-purple-gray border-top-left min-h-screen">
          <div className="relative md:px-10 rounded-3xl border-0 border-gray-800">
            <img
              className="fixed top-16 bottom-0 left-1/2 mt-32 transform -translate-x-1/2 object-contain z-0"
              src={HalfCircle}
              alt=""
            />
            {/* start wallet connect */}
            <div className="container mx-auto relative z-1">
              {/* please connect wallet */}
              {!wallet && (
                <div className="text-center border-2 border-gray-600 border-dashed rounded-2xl py-10 md:px-20 my-12">
                  <h2 className="mb-4 text-3xl md:text-5xl text-white font-bold">
                    Wallet Not Connected
                  </h2>
                  <p className="mx-auto mb-6 text-xl text-gray-400 leading-loose">
                    Please connect your wallet
                  </p>
                  <button
                    className="inline-block w-5/6 md:w-2/3 lg:w-1/3 py-3 bg-purple-600 text-white font-bold leading-loose text-xl md:text-2xl rounded-3xl transition duration-500 hover:bg-purple-800 hover:shadow-2xl hover:text-gray-200 hover"
                    onClick={() => {
                      connect()
                    }}
                  >
                    Connect a Wallet
                  </button>
                </div>
              )}
              {/* wallet connected */}
              {wallet && (
                <div className="flex justify-center md:justify-end mt-8 gap-2 px-3">
                  {wallet && (
                    <button
                      className="inline-flex justify-center items-center h-8 px-5 gap-0.5 text-gray-100 bg-gray-800 rounded-3xl transition duration-500 hover:bg-gray-900 hover:shadow-md"
                      onClick={() => {
                        disconnect(wallet)
                        window.localStorage.removeItem('connectedWallets')
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <p>Disconnect</p>
                    </button>
                  )}
                  {wallet && wallet?.dashboard && (
                    <button
                      className="inline-flex justify-center items-center h-8 px-5 gap-0.5 text-gray-100 bg-gray-600 rounded-3xl transition duration-500 hover:bg-gray-900 hover:shadow-md"
                      onClick={wallet?.dashboard}
                    >
                      Open Wallet Dashboard
                    </button>
                  )}
                  {wallet &&
                    wallet?.type === 'hardware' &&
                    wallet.accounts[0].address && (
                      <button
                        className="inline-flex justify-center items-center py-1 px-2 bg-purple-600 hover:bg-purple-700 gap-1 text-gray-100 font-medium rounded-l-xl rounded-t-xl transition duration-200 text-base"
                        onClick={web3Onboard.accountSelect}
                      >
                        Switch Account
                      </button>
                    )}
                </div>
              )}
              {wallet && (
                <div className="pt-10">
                  <h2 className="flex flex-wrap justify-center items-center gap-5  text-5xl md:text-8xl font-black mb-3">
                    <span>Welcome</span>
                    <a
                      href={
                        'https://polygonscan.com/address/' + connectedAddress
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex justify-center items-start gap-1 group"
                    >
                      <span className="gradient-text-purple">
                        {shortenedAddress}
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 md:mb-5 text-purple-400 group-hover:text-purple-500 transition"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                        />
                      </svg>
                    </a>
                  </h2>
                  {/* allow payee's to input a wallet address */}
                  {/* <div className="h-full w-full inline-flex flex-wrap justify-between rounded-t-xl items-center px-8 py-5">
                    <div className="pt-0.5 text-gray-400 text-sm px-0.5 flex items-center text-center md:text-left">
                      To lookup validator account:
                    </div>
                    <div className="inline-flex w-full lg:w-5/12 py-2 lg:py-0">
                      <input
                        id="address"
                        placeholder="Enter validator address"
                        onKeyPress={e => {
                          if (e.key === 'Enter') {
                            userInputAddress ===
                            document
                              .getElementById('address')
                              .value.replace(/[^a-z0-9]/gi, '')
                              ? setActiveWalletAddress(
                                  (activeWalletAddress = userInputAddress)
                                )
                              : setInputAddress(
                                  (userInputAddress = document
                                    .getElementById('address')
                                    .value.replace(/[^a-z0-9]/gi, ''))
                                )
                            // document.getElementById(
                            //   'validatorStatusDisplay'
                            // ).style.display = 'inline-flex'
                          }
                        }}
                        className="w-full h-8 rounded-l-3xl border border-r-0 border-purple-600 px-7 text-white text-sm bg-transparent"
                      />
                      <button
                        className="inline-flex justify-center items-center h-8 px-3 border-l-2 border-purple-600 bg-purple-600 hover:bg-purple-800 gap-1 text-gray-100 font-medium rounded-r-3xl transition duration-500"
                        onClick={() => {
                          userInputAddress ===
                          document
                            .getElementById('address')
                            .value.replace(/[^a-z0-9]/gi, '')
                            ? setActiveWalletAddress(
                                (activeWalletAddress = userInputAddress)
                              )
                            : setInputAddress(
                                (userInputAddress = document
                                  .getElementById('address')
                                  .value.replace(/[^a-z0-9]/gi, ''))
                              )
                          // document.getElementById(
                          //   'validatorStatusDisplay'
                          // ).style.display = 'inline-flex'
                        }}
                      >
                        Connect
                      </button>
                    </div>
                  </div> */}
                </div>
              )}
              {/* Apollo Wrapper and components */}
              {wallet && (
                <div className="container mx-auto mb-10">
                  <CallContract
                    activeWalletAddress={activeWalletAddress}
                    smartContract={smartContract}
                    smartContractV2={smartContractV2}
                    activeAddressValidatorStatus={activeAddressValidatorStatus}
                    connectedAddressValidatorStatus={
                      connectedAddressValidatorStatus
                    }
                    payeeSCv1={payeeSCv1}
                    payeeSCv2={payeeSCv2}
                    withdrawableAmountGweiSCv1={withdrawableAmountGweiSCv1}
                    withdrawableAmountGweiSCv2={withdrawableAmountGweiSCv2}
                    withdrawableAmountMaticSCv1={withdrawableAmountMaticSCv1}
                    withdrawableAmountMaticSCv2={withdrawableAmountMaticSCv2}
                  />
                  <ValidatorSettings
                    address={activeWalletAddress}
                    smartContract={smartContract}
                    smartContractV2={smartContractV2}
                    payeeSCv1={payeeSCv1}
                    payeeSCv2={payeeSCv2}
                    updateValidatorPayeeSCv1={updateValidatorPayeeSCv1}
                    updateValidatorPayeeSCv2={updateValidatorPayeeSCv2}
                  />
                  <ConnectedWalletInfo
                    address={wallet.accounts[0].address}
                    connectedChain={wallet ? connectedChain : null}
                    balance={wallet?.accounts[0]?.balance}
                    ens={wallet?.accounts[0]?.ens}
                  />
                </div>
              )}
            </div>
          </div>
          <Footer />
        </div>
      </section>
    </main>
  )
}

export default App
