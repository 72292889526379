import React, { useState } from 'react'
import { useAccountCenter, useSetLocale } from '@web3-onboard/react'
import FLlogo from '../../../assets/FL/FL-logo-WHT.svg'

const Footer = () => {
  const updateAccountCenter = useAccountCenter()
  const updateLocale = useSetLocale()
  const [locale, setLocale] = useState('en')

  return (
    <section className="pb-5 overflow-hidden">
      <div className="container mx-auto px-8 md:px-14">
        <div className="relative z-1 flex flex-wrap justify-between mb-6 lg:mb-6">
          <div className="mb-6 mr-4 w-full lg:w-1/5">
            <a className="text-white text-3xl font-bold leading-none" href="/">
              <img
                className="h-12 hover:h-[2.7rem] hover:ml-[2px] hover:mt-[3px] transition-all duration-300"
                src={FLlogo}
                alt="FastLane Logo"
                width="auto"
              />
            </a>
          </div>
          <div className="mb-5 ml-6 w-full lg:w-2/5">
            {/* <p className="text-gray-400 leading-loose">
            The FastLane protocol is designed to reduce transaction spam and improve network health
             by monetizing propagation bottlenecks in the peer-to-peer layer of the Polygon blockchain
              and distributing the proceeds to participating validators.
            </p> */}
          </div>
          <div className="w-full lg:w-1/6 flex flex-wrap justify-end">
            <div className="mb-6 w-full px-3 grid text-center md:text-left">
              <h5 className="mb-1 font-bold text-gray-50">Contact</h5>
              <a href="mailto:hello@fastlane.finance" className="text-gray-400">hello@fastlane.finance</a>
              <a href="mailto:support@fastlane.finance" className='text-gray-400'>support@fastlane.finance</a>
            </div>
          </div>
        </div>
        <div className="relative z-1 w-full flex items-end justify-between">
          <p className="text-sm text-gray-400">© 2022. All rights reserved.</p>
          {
            <div className="">
              <p className="text-gray-500 text-xs mb-2 ">Wallet language:</p>
              <button
                className=" bn-demo-button py-2 px-4 bg-gray-800 hover:bg-gray-700 text-gray-400 font-bold leading-loose rounded-l-xl rounded-t-xl transition duration-200 text-xs inline-flex justify-center items-center gap-1"
                onClick={e => {
                  updateLocale(locale === 'es' ? 'en' : 'es')
                  setLocale(locale === 'es' ? 'en' : 'es')
                  updateAccountCenter({ expanded: true })
                  e.stopPropagation()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                  />
                </svg>
                {locale === 'es' ? 'English' : 'Español'}
              </button>
            </div>
          }
        </div>
      </div>
    </section>
  )
}

export default Footer
