import React, { useState, useEffect } from 'react'
import EditIcon from '../../../assets/EditIcon'

export default function SliderOFA({ activeWalletAddress, smartContractV2 }) {
  const [value, setValue] = useState(50)
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [labelPosition, setLabelPosition] = useState('0px')
  const [currentOFAShare, setCurrentOFAShare] = useState(0)
  const [topStyle, setTopStyle] = useState('135%')

  const handleSetClick = async () => {
    try {
      setIsError(false)
      setIsSuccess(false)
      console.log('requesting update to validator refund share to:', value)

      var percentToWholeNumber = value * 100
      await smartContractV2.updateValidatorRefundShare(percentToWholeNumber)

      setIsSuccess(true) // Set success state on successful completion
    } catch (error) {
      console.error('Error when attempting to set:', error)
      setIsError(true)
    }
  }

  const openUserInput = () => {
    setIsEditing(!isEditing)
  }

  const handleBlur = () => {
    // Ensure the value is a number and restrict it to two decimal places
    // Also, ensure it falls within min and max range (0-100)
    const numericValue = parseFloat(value)
    const clampedValue = Math.min(100, Math.max(0, numericValue))
    setValue(clampedValue.toFixed(2))
  }

  useEffect(() => {
    if (!isEditing) {
      // Calculate position only when the slider is visible
      const newPosition = calculatePosition(value)
      setLabelPosition(`${newPosition}px`)
    }
  }, [isEditing, value])

  // Calculate the position for the label based on the slider's width and current value
  const calculatePosition = value => {
    // adjust based on the slider's actual width and thumb size
    const sliderWidth = document.querySelector('.slider')?.offsetWidth || 0
    const thumbWidth = 24 // The width of the thumb
    return (value / 100) * (sliderWidth - thumbWidth) + thumbWidth / 2
  }

  const handleSliderChange = event => {
    setValue(parseFloat(event.target.value).toFixed(2))
  }

  const labelStyle = {
    left: labelPosition,
    top: topStyle
  }

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      const numericValue = parseFloat(value)
      const clampedValue = Math.min(100, Math.max(0, numericValue))
      setValue(clampedValue.toFixed(2))
      setIsEditing(false)
    }
  }

  // Position the slider thumb in the center of the line based on screen size
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setTopStyle('135%')
      } else {
        setTopStyle('175%')
      }
    }

    // Set the initial value based on the current window size
    handleResize()

    // Add event listener
    window.addEventListener('resize', handleResize)

    // Clean up event listener
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /***********************************|
  |        SmartContract Calls        |
  |__________________________________*/

  useEffect(() => {
    async function fetchValidatorRefundShare() {
      try {
        const refundShare = await smartContractV2.validatorsRefundShareMap(
          activeWalletAddress
        )
        const percentValue = refundShare.toNumber() / 100
        setCurrentOFAShare(percentValue)
        setValue(percentValue)
      } catch (error) {
        console.error('Error fetching validator refund share:', error)
      }
    }

    fetchValidatorRefundShare()
  }, [activeWalletAddress, smartContractV2])

  useEffect(() => {
    if (isError) {
      // Remove the error state after a few seconds
      const timer = setTimeout(() => {
        setIsError(false)
      }, 4000) // Duration of the error message

      return () => clearTimeout(timer)
    } else if (isSuccess) {
      // Remove the success state after a few seconds
      const timer = setTimeout(() => {
        setIsSuccess(false)
      }, 4000) // Duration of the success message
      return () => clearTimeout(timer)
    }
  }, [isError, isSuccess])

  return (
    <div className="flex flex-col justify-between w-full md:w-1/2 md:pl-8 gap-6">
      <div className="flex flex-col w-full gap-2">
        <div className="gap-1">
          <span className="text-lg">{currentOFAShare}%</span>
          <span className="text-sm text-gray-500"> Your current OFA Share</span>
        </div>
        <div className="flex flex-col items-center text-gray-300">
          <div className="pb-1">
            Adjust the slider to set your desired share of MEV rewards, between
            0% for maximal Order Flow Auction (OFA) refunds and 100% for full
            validator retention.
          </div>
          <div>
            Keep in mind that a high value might entice OFA protocols to wait
            for another validator's sprint with a lower share requirement.
          </div>
          {/* OFA change request response */}
          {/* {isError ? (
            <div className="text-xs text-red-500">
              An error occurred. Please try again.
            </div>
          ) : isSuccess ? (
            <div className="text-xs text-green-500">Successfully updated.</div>
          ) : (
            <div className="text-xs"> </div>
          )} */}
          {isSuccess && (
            <div className="text-xs text-green-500">Successfully updated.</div>
          )}
        </div>
      </div>
      <div className="flex flex-wrap lg:flex-nowrap w-full gap-5 pb-4">
        <div className="slider-container w-full relative">
          {!isEditing && (
            <input
              type="range"
              min="0"
              max="100"
              value={value}
              step="0.01"
              onChange={handleSliderChange}
              className="slider transition-all duration-300"
            />
          )}

          {isEditing && (
            <div className="flex h-8 items-center justify-center bg-transparent border border-gray-400 rounded-2xl w-full transition-all duration-300 text-center text-white">
              <input
                type="text"
                value={value}
                onChange={e => setValue(e.target.value)}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                className="bg-transparent w-5/6 focus:outline-none text-center text-white"
              />
              <span className="pointer-events-none">%</span>
            </div>
          )}

          {!isEditing && (
            <div className="value-label" style={labelStyle}>
              {value}%
            </div>
          )}
        </div>
        <div className="flex w-full lg:w-auto justify-center items-center gap-2 mt-2 md:mt-0">
          <button onClick={openUserInput}>
            <EditIcon
              className="icon h-6 w-6"
              style={{ fill: 'rgba(209, 213, 219)' }}
            />
          </button>

          <button
            onClick={handleSetClick}
            className={`flex font-medium justify-center items-center h-8 w-full md:w-28 px-5 text-white bg-transparent border border-gray-400 rounded-2xl transition duration-500 hover:border-black hover:bg-black hover:text-white tooltip ${
              isError ? 'shake-animation' : ''
            }`}
          >
            Set
          </button>
        </div>
      </div>
    </div>
  )
}
