import dotenv from 'dotenv'
import { init } from '@web3-onboard/react'
import FlLogo from './assets/FL/fl-logo'
import { Network } from 'alchemy-sdk'
import { Alchemy } from 'alchemy-sdk'

import injectedModule from '@web3-onboard/injected-wallets'
import walletConnectModule from '@web3-onboard/walletconnect'
import coinbaseModule from '@web3-onboard/coinbase'
import portisModule from '@web3-onboard/portis'
import torusModule from '@web3-onboard/torus'
import keepkeyModule from '@web3-onboard/keepkey'
import gnosisModule from '@web3-onboard/gnosis'
import web3authModule from '@web3-onboard/web3auth'
import trezorModule from '@web3-onboard/trezor'
import ledgerModule from '@web3-onboard/ledger'
import magicModule from '@web3-onboard/magic'

dotenv.config()

// const RINKEBY_KEY = process.env.REACT_APP_RINKEBY
const ALCH_KEY = process.env.REACT_APP_ALCHEMY
const BNATIVE_KEY = process.env.REACT_APP_BLOCKNATIVE
const DAPP_URL = process.env.REACT_APP_DAPP_URL
const BLOCKNATIVE_CLIENT_ID = process.env.REACT_APP_BLOCKNATIVE_CLIENT_ID
const MAGIC_PK = process.env.REACT_APP_MAGIC_PUBLISHABLE_API_KEY
const PORTIS_KEY = process.env.REACT_APP_PORTIS_API_KEY
const EMAIL_FOR_TREZOR = process.env.REACT_APP_EMAIL_FOR_MAGIC

//Alchemy settings
export const AlchemySettings = {
  apiKey: `${ALCH_KEY}`,
  network: Network.MATIC_MAINNET
}
export const alchemy = new Alchemy(AlchemySettings)

const injected = injectedModule()
const coinbase = coinbaseModule()
const walletConnect = walletConnectModule()
const torus = torusModule()
const keepkey = keepkeyModule()
const gnosis = gnosisModule()
const ledger = ledgerModule()

const portis = portisModule({
  apiKey: `${PORTIS_KEY}`
})

const trezorOptions = {
  email: `${EMAIL_FOR_TREZOR}`,
  appUrl: `${DAPP_URL}`
}

const trezor = trezorModule(trezorOptions)

const magic = magicModule({
  apiKey: `${MAGIC_PK}`,
  userEmail: localStorage.getItem('magicUserEmail')
})

const web3auth = web3authModule({
  clientId: `${BLOCKNATIVE_CLIENT_ID}`
})

// initialize onboard function from @web3-onboard/react
export const initWeb3Onboard = init({
  wallets: [
    injected,
    ledger,
    coinbase,
    trezor,
    walletConnect,
    web3auth,
    gnosis,
    magic, //TODO: whitelist dapp url here: https://dashboard.magic.link/
    keepkey,
    portis, //TODO: whitelist dapp url here: https://dashboard.portis.io/
    torus
  ],
  chains: [
    {
      //hex encoded string for ethereum main net
      id: '0x89',
      //token symbol
      token: 'MATIC',
      //used for display in dapp
      label: 'Polygon',
      //used for network requests
      rpcUrl: `https://polygon-mainnet.g.alchemy.com/v2/${ALCH_KEY}`
    }
    // {
    //   //using rinkeby for testing
    //   id: '0x4',
    //   token: 'rETH',
    //   label: 'Rinkeby',
    //   rpcUrl: `https://eth-rinkeby.alchemyapi.io/v2/${RINKEBY_KEY}`
    // }
  ],
  appMetadata: {
    name: 'Validator Vault',
    icon: FlLogo,
    logo: FlLogo,
    description: 'Fastlane Web3 application',
    recommendedInjectedWallets: [
      { name: 'MetaMask', url: 'https://metamask.io' },
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' }
    ],
    // agreement: {
    //   version: '1.0.0',
    //   // termsUrl: 'https://fastlane.finance/TermsAndConditionsURL',
    //   // privacyUrl: 'https://fastlane.finance/PrivacyPolicyURL'
    // },
    // gettingStartedGuide: 'https://fastlane.finance/GettingStartedURL',
    explore: 'https://fastlane.finance'
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: true,
      minimal: true
    },
    mobile: {
      position: 'topRight',
      enabled: true,
      minimal: true
    }
  },
  // internationalization: Spanish menu
  i18n: {
    es: {
      connect: {
        selectingWallet: {
          header: 'Carteras disponibles',
          sidebar: {
            heading: 'Comenzar',
            subheading: 'Conecta tu monedero',
            paragraph:
              'Conectar su billetera es como “iniciar sesión” en Web3. Seleccione su billetera de las opciones para comenzar.'
          }
        }
      },
      accountCenter: {
        connectAnotherWallet: 'Conectar otro monedero',
        disconnectAllWallets: 'Desconectar todos los monederos',
        currentNetwork: 'Red actual',
        appInfo: 'Información de la aplicación',
        learnMore: 'Aprende más',
        gettingStartedGuide: 'Guía de introducción',
        smartContracts: 'Contrato(s) inteligente',
        explore: 'Explorar',
        backToApp: 'Volver a dapp',
        poweredBy: 'Funciona con',
        addAccount: 'Añadir cuenta',
        setPrimaryAccount: 'Establecer cuenta principal',
        disconnectWallet: 'Desconectar Wallet'
      }
    }
  },
  apiKey: BNATIVE_KEY,
  notify: {
    transactionHandler: transaction => {
      if (transaction.eventCode === 'txPool') {
        return {
          autoDismiss: 2000,
          message: 'transaction is pending',
          onClick: () =>
            window.open(`https://etherscan.io/tx/${transaction.hash}`)
        }
      }
      if (transaction.eventCode === 'txConfirmed') {
        return {
          autoDismiss: 5000,
          message: 'transaction sent successfully',
          onClick: () =>
            window.open(`https://etherscan.io/tx/${transaction.hash}`)
        }
      }
    }
  }
})
