import React, { useState, useEffect } from 'react'

const WithdrawButton = ({
  activeWalletAddress,
  selectedAccount,
  smartContract,
  smartContractV2,
  payeeSCv1,
  payeeSCv2,
  readyToInteract
}) => {
  const [isError, setIsError] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  const handleWithdraw = async withdrawFunction => {
    try {
      setIsError(false)
      setIsSuccess(false)
      readyToInteract()
      await withdrawFunction() // Execute the provided withdrawal function
      setIsSuccess(true) // Set success state
    } catch (error) {
      console.error('Withdrawal error:', error)
      setIsError(true)
    }
  }

  useEffect(() => {
    let timer
    if (isError || isSuccess) {
      timer = setTimeout(() => {
        setIsError(false)
        setIsSuccess(false)
      }, 4000) // Reset states after 5 seconds
    }
    return () => clearTimeout(timer) // Clear timeout on component unmount or state changes
  }, [isError, isSuccess])

  return (
    <div className="flex flex-col w-full sm:w-auto justify-center sm:justify-end items-center pt-2 sm:pt-0">
      {isError && <div className="text-red-500 py-2">Error occured</div>}
      {isSuccess && <div className="text-green-500 py-2">Success</div>}
      <button
        className={`inline-flex justify-center items-center h-8 w-full sm:w-min px-5 text-black bg-white rounded-2xl transition duration-500 hover:bg-black hover:text-white hover:shadow-md tooltip ${
          isError ? 'shake-animation' : ''
        }`}
        onClick={() => {
          // Decide which withdraw function to call based on the selected account
          if (selectedAccount === 'SCv1') {
            handleWithdraw(() =>
              smartContract.payValidator(activeWalletAddress)
            )
          } else if (selectedAccount === 'SCv2') {
            handleWithdraw(() => smartContractV2.collectFees())
          } else if (selectedAccount === 'Total') {
            handleWithdraw(() =>
              smartContract.payValidator(activeWalletAddress)
            )
            handleWithdraw(() => smartContractV2.collectFees())
          }
        }}
      >
        <span className="pt-px tooltip font-medium">
          Withdraw
          {/* Show the payee based on the selected account */}
          <span className="tooltipText70Right">
            {selectedAccount === 'SCv2' ? payeeSCv2 : payeeSCv1}
          </span>
        </span>
      </button>
    </div>
  )
}
export default WithdrawButton
