import React, { useCallback, useEffect, useState } from 'react'
import { ethers } from 'ethers'
import { useSetChain } from '@web3-onboard/react'
import SelectWithdrawAccount from './SelectWithdrawAccount'
import CurrencySelect from './CurrencySelect'
import WithdrawButton from './WithdrawButton'
import { truncateDecimal } from '../../utils/utils'

const CallContract = ({
  // user inputted address
  activeWalletAddress,
  smartContract,
  smartContractV2
  // connectedAddressValidatorStatus
}) => {
  var [payeeSCv1, setPayeeSCv1] = useState(
    '0x0000000000000000000000000000000000000000'
  )
  var [payeeSCv2, setPayeeSCv2] = useState(
    '0x0000000000000000000000000000000000000000'
  )
  var [withdrawableAmountMaticSCv1, setWithdrawableAmountMaticSCv1] =
    useState(0)
  var [withdrawableAmountGweiSCv1, setWithdrawableAmountGweiSCv1] = useState(0)
  var [withdrawableAmountMaticSCv2, setWithdrawableAmountMaticSCv2] =
    useState(0)
  var [withdrawableAmountGweiSCv2, setWithdrawableAmountGweiSCv2] = useState(0)
  const [{ connectedChain }, setChain] = useSetChain()

  const currencyUpArrow = document.getElementById('currencyUpArrow')
  const currencyDownArrow = document.getElementById('currencyDownArrow')
  const currencyModal = document.getElementById('currencyModal')
  const currencyUpArrowAccounts = document.getElementById(
    'currencyUpArrowAccounts'
  )
  const currencyDownArrowAccounts = document.getElementById(
    'currencyDownArrowAccounts'
  )
  const currencyModalAccounts = document.getElementById('currencyModalAccounts')
  const [selectedAccount, setSelectedAccount] = useState('Total')
  const [selectedCurrency, setSelectedCurrency] = useState('matic')
  const [isCurrencyChevronRotated, setIsCurrencyChevronRotated] =
    useState(false)
  const [isRotated, setIsRotated] = useState(false)

  const setDisplay = useCallback((elements, displayStyle) => {
    elements.forEach(element => {
      if (element) {
        element.style.display = displayStyle
      }
    })
  }, [])

  // Set currency select (gwei/MATIC) modals to closed on load
  const [modalStates, setModalStates] = useState({
    SCv1: false,
    SCv2: false,
    AllSC: false,
    Currency: false,
    Accounts: false
  })

  // Set initial styles for currency selection (gwei/MATIC) modals
  const currencyElements = {
    Accounts: {
      upArrow: currencyUpArrowAccounts,
      downArrow: currencyDownArrowAccounts,
      modal: currencyModalAccounts,
      AccountSCv1: document.getElementById('AccountsSCv1'),
      AccountSCv2: document.getElementById('AccountsSCv2'),
      AccountTotal: document.getElementById('AccountsTotal')
    },
    Currency: {
      upArrow: currencyUpArrow,
      downArrow: currencyDownArrow,
      modal: currencyModal,
      gwei: document.getElementById('withdrawableGwei'),
      gweiAmt: document.getElementById('withdrawableGweiAmt'),
      matic: document.getElementById('withdrawableMatic'),
      maticAmt: document.getElementById('withdrawableMaticAmt')
    }
  }

  function openCurrencyModal(version) {
    setDisplay([currencyElements[version].modal], 'flex') // Show modal component

    // Update modalStates using the setter from useState
    setModalStates(prevModalStates => ({
      ...prevModalStates,
      [version]: true
    }))
  }

  const closeCurrencyModal = useCallback(
    version => {
      setDisplay([currencyElements[version].modal], 'none')
      // setDisplay([currencyElements[version].downArrow], 'flex')

      // Update modalStates using the setter from useState
      setModalStates(prevModalStates => ({
        ...prevModalStates,
        [version]: false
      }))
    },
    [setDisplay, currencyElements, setModalStates]
  )

  // Close currency modals when user clicks outside of them
  useEffect(() => {
    const handleClickOutsideModal = event => {
      Object.keys(modalStates).forEach(version => {
        if (
          modalStates[version] &&
          !event.target.closest(`.currencyDropdown${version}`)
        ) {
          closeCurrencyModal(version)
          // Rotate back to original position when closing the modal
          setIsRotated(false)
          setIsCurrencyChevronRotated(false)
        }
      })
    }

    document.addEventListener('click', handleClickOutsideModal, true)

    // Remove the event listener when the component unmounts or dependencies change
    return () => {
      document.removeEventListener('click', handleClickOutsideModal, true)
    }
  }, [closeCurrencyModal, modalStates]) // Run if modalStates changes

  // Toggle the display of currency amounts
  function toggleCurrencyDisplay(version, currency) {
    setSelectedCurrency(currency)
    const displayGwei = currency === 'gwei' ? 'flex' : 'none'
    const displayMatic = currency === 'matic' ? 'flex' : 'none'

    setDisplay(
      [currencyElements[version].gwei, currencyElements[version].gweiAmt],
      displayGwei
    )
    setDisplay(
      [currencyElements[version].matic, currencyElements[version].maticAmt],
      displayMatic
    )
  }

  function displayAccount(selectedAccount) {
    setSelectedAccount(selectedAccount)
    // Hide all accounts first
    setDisplay(
      [
        currencyElements['Accounts'].AccountSCv1,
        currencyElements['Accounts'].AccountSCv2,
        currencyElements['Accounts'].AccountTotal
      ],
      'none'
    )

    // based on the selected account, show the appropriate one
    if (selectedAccount === 'SCv1') {
      setDisplay([currencyElements['Accounts'].AccountSCv1], 'flex')
    } else if (selectedAccount === 'SCv2') {
      setDisplay([currencyElements['Accounts'].AccountSCv2], 'flex')
    } else if (selectedAccount === 'Total') {
      setDisplay([currencyElements['Accounts'].AccountTotal], 'flex')
    }
  }

  // Display withdrawable amounts in gwei
  function displayGwei(version) {
    setSelectedCurrency('gwei')
    toggleCurrencyDisplay(version, 'gwei')
  }

  // Display withdrawable amounts in MATIC
  function displayMatic(version) {
    setSelectedCurrency('matic')
    toggleCurrencyDisplay(version, 'matic')
  }

  // Prompt user to swap to Polygon Network on wallet connect
  useEffect(() => {
    if (connectedChain && connectedChain.id !== '0x89') {
      setChain({ chainId: '0x89' })
    }
  }, [connectedChain, setChain])

  // Check if user is connected to Polygon Network
  const readyToInteract = async () => {
    if (connectedChain && connectedChain.id !== '0x89') {
      // prompt user to switch to Polygon to withdraw
      await setChain({ chainId: '0x89' })
    }
  }

  /***********************************|
  |        SmartContract Calls        |
  |__________________________________*/

  // request data from smart contract using current wallet address
  async function CallSmartContract(activeWalletAddress) {
    try {
      //gets validator's payee; Used to display payee address on hover of "Withdraw" button on old smart contract
      const getPayeeSCv1 = await smartContract.getValidatorRecipient(
        activeWalletAddress
      )
      //gets pending balance on old SC; displayed
      const getPendingBalance = await smartContract.getValidatorBalance(
        activeWalletAddress
      )

      // const getValidatorStatus = await smartContract.getValidatorStatus(

      // sets variables from data or calls functions when promised is received
      getPayeeSCv1 && setPayeeSCv1(getPayeeSCv1)

      setWithdrawableAmountMaticSCv1(
        truncateDecimal(ethers.utils.formatUnits(getPendingBalance, 18))
      )
      setWithdrawableAmountGweiSCv1(
        Math.round(ethers.utils.formatUnits(getPendingBalance, 9))
      )
    } catch (err) {
      // console.log(`error connecting to smart contract - `, err)
    }
  }
  CallSmartContract(activeWalletAddress)

  async function CallSmartContractV2(activeWalletAddress) {
    try {
      //gets validator's payee; Used to display payee address on hover of "Withdraw" button for KairosUpdate withdraw
      const getPayeeSCv2 = await smartContractV2.getValidatorRecipient(
        activeWalletAddress
      )
      //gets pending balance on KairosUpdate SC; displayed
      const getPendingBalanceSCv2 = await smartContractV2.getValidatorBalance(
        activeWalletAddress
      )

      // get payee from KairosUpdate SC (SCv2)
      getPayeeSCv2 && setPayeeSCv2(getPayeeSCv2)

      setWithdrawableAmountMaticSCv2(
        truncateDecimal(ethers.utils.formatUnits(getPendingBalanceSCv2, 18))
      )
      setWithdrawableAmountGweiSCv2(
        Math.round(ethers.utils.formatUnits(getPendingBalanceSCv2, 9))
      )
    } catch (err) {
      // console.log(`error connecting to smart contract - `, err)
    }
  }
  CallSmartContractV2(activeWalletAddress)

  return (
    <div className="relative z-1">
      <div className="h-full w-full flex flex-wrap rounded-xl bg-gray-800 justify-between px-8 py-4">
        {/* Withdrawable section */}
        <div className="w-full flex flex-wrap sm:flex-nowrap wrap justify-between">
          <div className="flex flex-col gap-2 w-full md:w-auto">
            <SelectWithdrawAccount
              modalStates={modalStates}
              openCurrencyModal={openCurrencyModal}
              closeCurrencyModal={closeCurrencyModal}
              displayAccount={displayAccount}
              withdrawableAmountMaticSCv1={withdrawableAmountMaticSCv1}
              withdrawableAmountMaticSCv2={withdrawableAmountMaticSCv2}
              isRotated={isRotated}
              setIsRotated={setIsRotated}
            />
            <CurrencySelect
              selectedCurrency={selectedCurrency}
              selectedAccount={selectedAccount}
              withdrawableAmountMaticSCv1={withdrawableAmountMaticSCv1}
              withdrawableAmountMaticSCv2={withdrawableAmountMaticSCv2}
              withdrawableAmountGweiSCv1={withdrawableAmountGweiSCv1}
              withdrawableAmountGweiSCv2={withdrawableAmountGweiSCv2}
              modalStates={modalStates}
              openCurrencyModal={openCurrencyModal}
              closeCurrencyModal={closeCurrencyModal}
              displayMatic={displayMatic}
              displayGwei={displayGwei}
              isCurrencyChevronRotated={isCurrencyChevronRotated}
              setIsCurrencyChevronRotated={setIsCurrencyChevronRotated}
            />
          </div>
          <WithdrawButton
            selectedAccount={selectedAccount}
            activeWalletAddress={activeWalletAddress}
            smartContract={smartContract}
            smartContractV2={smartContractV2}
            readyToInteract={readyToInteract}
            payeeSCv1={payeeSCv1}
            payeeSCv2={payeeSCv2}
          />
        </div>
        <div className="text-sm md:text-md text-gray-300 mt-1">
          Your withdrawable
        </div>
      </div>
    </div>
  )
}
export default CallContract
