import React from 'react'
import ChangePayee from '../../Layout/StatsAndInteraction/ChangePayee'
import SliderOFA from '../../Layout/StatsAndInteraction/SliderOFA'
export default function ValidatorSettingsDisplayer({
  activeWalletAddress,
  smartContractV2,
  payeeSCv1,
  payeeSCv2,
  updateValidatorPayeeSCv1,
  updateValidatorPayeeSCv2
}) {
  return (
    <section>
      <div className="text-white grid mt-5">
        <div className="w-full flex flex-wrap justify-start bg-gray-800 rounded-xl py-4 px-8 gap-5 md:gap-0">
          {/* Payee address display and settings */}
          <ChangePayee
            payeeSCv1={payeeSCv1}
            payeeSCv2={payeeSCv2}
            updateValidatorPayeeSCv1={updateValidatorPayeeSCv1}
            updateValidatorPayeeSCv2={updateValidatorPayeeSCv2}
          />
          <SliderOFA
            smartContractV2={smartContractV2}
            activeWalletAddress={activeWalletAddress}
          />
        </div>
      </div>
    </section>
  )
}
