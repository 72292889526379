import React from 'react'
import networkEnum from '../../../networkEnum'
import { useConnectWallet } from '@web3-onboard/react'

// This component is used to show:
//    balance of currently connected wallet address
//    refresh balance of currently connected wallet address
//    currently connected blockchain/ network

const WalletInfo = props => {
  const { connectedChain, balance } = props
  const [{ wallet }, updateBalances] = useConnectWallet()

  return (
    <div className="flex flex-wrap justify-center sm:justify-between px-4 mt-10">
      {balance != null && (
        <div className="text-white inline-flex justify-center items-center gap-2 mb-1">
          {Object.keys(balance).map((key, i) => (
            <div key={key} className="inline-flex gap-2 flex-wrap justify-center md:justify-start">
              <span className="text-gray-400 font-medium">
                Wallet balance:{' '}
              </span>
              {balance[key]} {key}
              <button
              className="grid h-6 w-6 justify-center items-center bg-purple-600 hover:bg-purple-700 text-white font-bold leading-loose rounded-3xl transition duration-200"
              onClick={() => updateBalances}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                />
              </svg>
            </button>
            </div>
          ))}


        </div>
      )}
      {connectedChain && connectedChain?.id && (
        <div className="text-white gap-1 mb-1">
          <span className="text-gray-400 font-medium">Connected to: </span>
          {networkEnum?.[connectedChain.id] || 'local'} Network
        </div>
      )}
    </div>
  )
}

export default WalletInfo
