import React from 'react'
import ValidatorSettingsDisplayer from '../Displayers/ValidatorSettingsDisplayer'

export default function ValidatorSettings({
  address,
  smartContract,
  smartContractV2,
  payeeSCv1,
  payeeSCv2,
  updateValidatorPayeeSCv1,
  updateValidatorPayeeSCv2
}) {
  return (
    <div>
      <ValidatorSettingsDisplayer
        activeWalletAddress={address}
        smartContract={smartContract}
        smartContractV2={smartContractV2}
        payeeSCv1={payeeSCv1}
        payeeSCv2={payeeSCv2}
        updateValidatorPayeeSCv1={updateValidatorPayeeSCv1}
        updateValidatorPayeeSCv2={updateValidatorPayeeSCv2}
      />
    </div>
  )
}
