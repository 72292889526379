import React from 'react'
import maticIcon from '../../../assets/tokens/maticIcon.png'

const CurrencySelect = ({
  selectedCurrency,
  selectedAccount,
  withdrawableAmountMaticSCv1,
  withdrawableAmountMaticSCv2,
  withdrawableAmountGweiSCv1,
  withdrawableAmountGweiSCv2,
  modalStates,
  openCurrencyModal,
  closeCurrencyModal,
  displayMatic,
  displayGwei,
  isCurrencyChevronRotated,
  setIsCurrencyChevronRotated
}) => {
  const toggleDropdownAndChevron = () => {
    setIsCurrencyChevronRotated(!isCurrencyChevronRotated) // Toggle chevron rotation
    // Toggle dropdown modal
    if (modalStates['Currency'] === false) {
      openCurrencyModal('Currency')
      setIsCurrencyChevronRotated(true)
    } else if (modalStates['Currency'] === true) {
      closeCurrencyModal('Currency')
      setIsCurrencyChevronRotated(false)
    }
  }

  const handleCurrencyChange = currencyType => {
    if (currencyType === 'matic') {
      displayMatic('Currency')
    } else if (currencyType === 'gwei') {
      displayGwei('Currency')
    }
    closeCurrencyModal('Currency')
    setIsCurrencyChevronRotated(false) // Reset chevron rotation
  }

  return (
    <div className="flex flex-row items-center gap-2">
      {/* Display the withdrawable amount based on the selected account */}
      {selectedCurrency === 'matic' && (
        <span
          id="withdrawableAmountsMatic"
          className="text-xl md:text-2xl font-semibold text-white w-min"
        >
          {selectedAccount === 'SCv1' &&
            withdrawableAmountMaticSCv1.toLocaleString('en-US')}
          {selectedAccount === 'SCv2' &&
            withdrawableAmountMaticSCv2.toLocaleString('en-US')}
          {selectedAccount === 'Total' &&
            (
              withdrawableAmountMaticSCv1 + withdrawableAmountMaticSCv2
            ).toLocaleString('en-US')}
        </span>
      )}
      {selectedCurrency === 'gwei' && (
        <span
          id="withdrawableAmountsGwei"
          className="text-xl md:text-2xl font-semibold text-white w-min"
        >
          {selectedAccount === 'SCv1' &&
            withdrawableAmountGweiSCv1.toLocaleString('en-US')}
          {selectedAccount === 'SCv2' &&
            withdrawableAmountGweiSCv2.toLocaleString('en-US')}
          {selectedAccount === 'Total' &&
            (
              withdrawableAmountGweiSCv1 + withdrawableAmountGweiSCv2
            ).toLocaleString('en-US')}
        </span>
      )}
      {/* Withdraw Currency Drop Down */}
      <div className="relative flex currencyDropdownCurrency">
        <div
          className="group w-full flex justify-center border rounded-3xl border-gray-700 h-8 px-2 cursor-pointer transition duration-500 hover:border-purple-400 bg-black hover:shadow-md w-[120px]"
          onClick={toggleDropdownAndChevron}
        >
          <div className="flex items-center gap-1" id="withdrawableMatic">
            <img src={maticIcon} className="w-5 h-5" alt="MATIC Token Icon" />
            <span className="text-white py-0.5 rounded-md text-base font-medium">
              MATIC
            </span>
          </div>
          <div className="items-center gap-2 hidden" id="withdrawableGwei">
            <span className="text-green-200 rounded-md text-base font-medium">
              GWEI
            </span>
          </div>
          <div
            id="currencyDownArrow"
            className={`flex items-center justify-end ml-1 text-gray-300 transition transform duration-300 ${
              isCurrencyChevronRotated ? 'rotate-180' : ''
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-4 h-4 group-hover:text-purple-300 transition duration-500"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <div
            id="currencyUpArrow"
            className="hidden item-center justify-end ml-1 mt-1 text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-4 h-4 group-hover:text-purple-300 transition"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          </div>
        </div>
        <div
          id="currencyModal"
          className="z-1 hidden flex-col w-full sm:w-28 border border-gray-600 bg-gray-800 bg-opacity-80 justify-center py-1 px-1.5 sm:mt-1 gap-0 rounded-lg absolute top-8"
        >
          <div
            onClick={() => handleCurrencyChange('matic')}
            className="cursor-pointer w-full hover:bg-gray-900 bg-opacity-75 grid justify-center py-1 rounded-lg"
          >
            <div className="flex items-center gap-1" id="withdrawableMatic">
              <img src={maticIcon} className="w-5 h-5" alt="MATIC Token Icon" />
              <span className=" text-white py-0.5 rounded-md text-base font-medium">
                MATIC
              </span>
            </div>
          </div>
          <div
            className="cursor-pointer w-full hover:bg-gray-900 bg-opacity-75 grid justify-center py-1 rounded-lg"
            onClick={() => handleCurrencyChange('gwei')}
          >
            <h1 className="bg-green-200 w-min text-green-700 px-1 py-0.5 rounded-md text-base font-medium text-center group-hover:bg-green-300">
              GWEI
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}
export default CurrencySelect
