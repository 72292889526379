export default `
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 47.8 40.4" style="enable-background:new 0 0 47.8 40.4;" xml:space="preserve">
<style type="text/css">
	.st0{fill:#7C3AED;}
</style>
<g id="_x32_a4a8eb3-2a04-4926-a8d9-1e5f60066483" transform="matrix(0.7326953041576726,0,0,0.7326953041576726,39.26730469584233,126.55375026695447)">
	<path class="st0" d="M-2.6-147.9l9-9l1.4,1.4l-9,9L-2.6-147.9z M-6.6-143.9l2-2l1.4,1.4l-2,2L-6.6-143.9z"/>
	<path class="st0" d="M1.1-168.2l-19,19l-5-5l-8.8,8.8c2.5,1.4,4.6,3.5,6,6l2.8-2.8l5,5l25-25l3,3v-12h-12L1.1-168.2z M-38.9-119.2
		c7.2,0,13-5.8,13-13c0-7.2-5.8-13-13-13s-13,5.8-13,13S-46.1-119.2-38.9-119.2z M-44.9-135.2l6-3l6,3v6l-6,3l-6-3V-135.2z"/>
</g>
</svg>
`
