import React, { useState, useEffect } from 'react'

export default function ChangePayee({
  payeeSCv1,
  payeeSCv2,
  updateValidatorPayeeSCv1,
  updateValidatorPayeeSCv2
}) {
  const [userInput, setUserInput] = useState('')
  const [isSuccessSCv1, setIsSuccessSCv1] = useState(false)
  const [isErrorSCv1, setIsErrorSCv1] = useState(false)
  const [isSuccessSCv2, setIsSuccessSCv2] = useState(false)
  const [isErrorSCv2, setIsErrorSCv2] = useState(false)

  const updateValidatorPayee = async newPayee => {
    try {
      // Reset the states for both SCv1 and SCv2
      setIsErrorSCv1(false)
      setIsSuccessSCv1(false)
      setIsErrorSCv2(false)
      setIsSuccessSCv2(false)

      // Update SC v1 payee
      const resultSCv1 = await updateValidatorPayeeSCv1(newPayee)
      if (resultSCv1) {
        setIsSuccessSCv1(true)
      } else {
        setIsErrorSCv1(true)
      }

      // Update SC v2 payee
      const resultSCv2 = await updateValidatorPayeeSCv2(newPayee)
      if (resultSCv2) {
        setIsSuccessSCv2(true)
      } else {
        setIsErrorSCv2(true)
      }
    } catch (error) {
      console.error('Error when attempting to update payee:', error)
      // If an error occurs, set both states to error
      setIsErrorSCv1(true)
      setIsErrorSCv2(true)
    }
  }

  useEffect(() => {
    if (isErrorSCv1) {
      // Remove the error state after a few seconds
      const timer = setTimeout(() => {
        setIsErrorSCv1(false)
      }, 4000) // Duration of the error message

      return () => clearTimeout(timer)
    } else if (isSuccessSCv1) {
      // Remove the success state after a few seconds
      const timer = setTimeout(() => {
        setIsSuccessSCv1(false)
      }, 4000) // Duration of the success message
      return () => clearTimeout(timer)
    }
  }, [isErrorSCv1, isSuccessSCv1])

  useEffect(() => {
    if (isErrorSCv2) {
      // Remove the error state after a few seconds
      const timer = setTimeout(() => {
        setIsErrorSCv2(false)
      }, 4000) // Duration of the error message

      return () => clearTimeout(timer)
    } else if (isSuccessSCv2) {
      // Remove the success state after a few seconds
      const timer = setTimeout(() => {
        setIsSuccessSCv2(false)
      }, 4000) // Duration of the success message
      return () => clearTimeout(timer)
    }
  }, [isErrorSCv2, isSuccessSCv2])

  const handleChange = event => {
    const result = event.target.value.replace(/[^a-z0-9]/gi, '')
    setUserInput(result)
  }

  function allowUserInput() {
    document.getElementById('currentPayeeDisplay').style.display = 'none'
    document.getElementById('newPayee').style.display = 'flex'
    document.getElementById('settingsIcon').style.display = 'none'
    document.getElementById('checkmarkIcon').style.display = 'grid'
  }

  /***********************************|
  |        SmartContract Calls        |
  |__________________________________*/

  return (
    <div className="flex flex-col w-full md:w-1/2 text-xl">
      <div className="flex w-full flex-wrap lg:flex-nowrap items-center gap-2">
        <div className="flex flex-wrap md:flex-nowrap items-center justify-between w-full text-md gap-2">
          <span className="w-auto whitespace-nowrap">Payee Address</span>
          <div className="flex w-full justify-start md:justify-end">
            {/* Change payee - user input */}
            <input
              className="hidden h-8 text-sm items-center justify-center px-2 bg-transparent border border-gray-400 rounded-2xl w-full transition-all duration-300 text-center text-white"
              type="text"
              id="newPayee"
              value={userInput}
              placeholder={payeeSCv1 ? payeeSCv1 : 'Enter Payable Address'}
              onChange={handleChange}
              onKeyPress={e => {
                if (e.key === 'Enter' && userInput.trim()) {
                  updateValidatorPayee(userInput.trim())
                }
              }}
            />
            <a
              href={'https://polygonscan.com/address/' + payeeSCv1}
              id="currentPayeeDisplay"
              target="_blank"
              rel="noopener noreferrer"
              className="hover:text-purple-400"
            >
              <span className="tooltip">
                {payeeSCv1.slice(0, 7) + '...' + payeeSCv1.slice(-5)}
                <span className="tooltipText60Center font-normal w-full text-base">
                  {payeeSCv1}
                </span>
              </span>
            </a>
          </div>
        </div>
        <div className="flex w-full md:w-auto items-center font-semibold justify-end text-right">
          {/* Change payee - settings button */}
          <button
            className="inline-flex justify-center items-center h-8 w-full md:w-28 px-3 text-white bg-transparent border border-gray-400 font-medium rounded-3xl transition duration-500 hover:border-black hover:bg-black hover:text-white"
            id="settingsIcon"
            onClick={() => {
              allowUserInput()
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 grid"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 6h9.75M10.5 6a1.5 1.5 0 11-3 0m3 0a1.5 1.5 0 10-3 0M3.75 6H7.5m3 12h9.75m-9.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-3.75 0H7.5m9-6h3.75m-3.75 0a1.5 1.5 0 01-3 0m3 0a1.5 1.5 0 00-3 0m-9.75 0h9.75"
              />
            </svg>{' '}
            <span className="ml-2 font-medium text-base">Update</span>
          </button>
          {/* Change payee - checkmark button */}
          <button
            className={`hidden justify-center items-center h-8 w-full md:w-28 text-white bg-transparent border border-gray-400 font-medium rounded-3xl transition duration-500 hover:border-black hover:bg-black hover:text-white ${
              isErrorSCv2 ? 'shake-animation' : ''
            }`}
            id="checkmarkIcon"
            onClick={() => {
              if (userInput.trim()) {
                updateValidatorPayee(userInput.trim())
              }
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12.75l6 6 9-13.5"
              />
            </svg>
          </button>
        </div>
      </div>
      {isErrorSCv1 ? (
        <div className="text-red-500 py-2 text-sm">
          An error occurred in Smart Contract v1. Please try again.
        </div>
      ) : isSuccessSCv1 ? (
        <div className="text-green-500 py-2 text-sm">
          Successfully updated Smart Contract v1.
        </div>
      ) : (
        <div className=" py-2"> </div>
      )}
      {isErrorSCv2 ? (
        <div className="text-red-500 py-2 text-sm">
          An error occurred in Smart Contract v2. Please try again.
        </div>
      ) : isSuccessSCv2 ? (
        <div className="text-green-500 py-2 text-sm">
          Successfully updated Smart Contract v2.
        </div>
      ) : (
        <div className="py-2 text-sm">{'    '}</div>
      )}
    </div>
  )
}
