import React from 'react'
import logo from '../../../assets/FL/FL-logo-BLK.svg'

const Navbar = () => {
  return (
    <section className="min-h-24">
      <nav className="h-navbar fixed grid w-full top-0 z-2 items-center frosted-glass">
        <div className="container mx-auto px-6 flex justify-between items-center">
          {/* navbar fastlane logo */}
          <a className="text-3xl font-bold leading-none" href="/">
            <img className="h-10" src={logo} alt="" width="auto" />
          </a>
          {/* navbar navigation buttons */}
          {/* <ul className="hidden absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 lg:flex lg:mx-auto lg:items-center lg:w-auto lg:space-x-6">
              <li>
                <a
                className="text-sm text-black hover:text-gray-700"
                  href="/"
                >
                  App
                </a>
                </li>
              <li className="text-gray-600">
                <svg
                  className="w-4 h-4 current-fill"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                  />
                  </svg>
                  </li>
              <li>
                <a
                  className="text-sm text-black hover:text-gray-700"
                  href="/"
                >
                  Charts
                  </a>
              </li>
            </ul> */}
        </div>
      </nav>
    </section>
  )
}

export default Navbar
