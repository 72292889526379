import React from 'react'
import maticIcon from '../../../assets/tokens/maticIcon.png'

const SelectWithdrawAccount = ({
  modalStates,
  openCurrencyModal,
  closeCurrencyModal,
  displayAccount,
  withdrawableAmountMaticSCv1,
  withdrawableAmountMaticSCv2,
  isRotated,
  setIsRotated
}) => {
  const toggleModalAndRotation = () => {
    setIsRotated(!isRotated)

    if (modalStates['Accounts'] === false) {
      openCurrencyModal('Accounts')
      setIsRotated(true) // Rotate to open position
    } else if (modalStates['Accounts'] === true) {
      closeCurrencyModal('Accounts')
      setIsRotated(false) // Rotate to close position
    }
  }

  const handleAccountSelection = account => {
    displayAccount(account)
    closeCurrencyModal('Accounts')
    setIsRotated(false) // Reset rotation when an account is selected
  }

  return (
    <div className="w-full flex">
      {/* Selected Withdrawable Account */}
      <div className="relative flex currencyDropdownAccounts">
        <div
          className="group w-auto flex justify-center rounded-3xl h-8 px-3 cursor-pointer transition duration-500 border border-gray-400 hover:border-purple-400 hover:shadow-md w-[120px]"
          onClick={toggleModalAndRotation}
        >
          <div className="flex items-center" id="AccountsTotal">
            <span className="text-white rounded-3xl font-medium">Total</span>
          </div>
          <div className="hidden items-center" id="AccountsSCv1">
            <span className="text-white rounded-3xl font-medium">
              Smart Contract v1
            </span>
          </div>
          <div className="hidden items-center" id="AccountsSCv2">
            <span className="text-white rounded-3xl font-medium">
              Smart Contract v2
            </span>
          </div>
          <div
            id="currencyDownArrowAccounts"
            className="flex items-center justify-end ml-1 text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className={`w-4 h-4 group-hover:text-purple-300 transition duration-300 transform ${
                isRotated ? 'rotate-180' : ''
              }`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </div>
          <div
            id="currencyUpArrowAccounts"
            className="hidden item-center justify-end ml-1 mt-1.5 text-gray-300"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={3}
              stroke="currentColor"
              className="w-4 h-4 group-hover:text-purple-300 transition"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 15.75l7.5-7.5 7.5 7.5"
              />
            </svg>
          </div>
        </div>
        {/* Account Dropdown Modal */}
        <div
          id="currencyModalAccounts"
          className="z-1 hidden flex-col whitespace-nowrap w-auto border border-gray-600 bg-gray-800 bg-opacity-80 justify-center py-1 px-1.5 sm:mt-1 rounded-lg absolute top-10"
        >
          <div className="font-bold py-1.5 text-center mb-1">
            Select withdrawable
          </div>
          {/* Account: Total */}
          <div
            onClick={() => handleAccountSelection('Total')}
            className="cursor-pointer flex w-full hover:bg-gray-900 bg-opacity-75 justify-between rounded-lg py-1.5 px-3"
          >
            <div className="flex items-center text-white rounded-md text-base font-medium w-44">
              Total
            </div>
            <div className="flex items-center gap-1">
              <img src={maticIcon} className="w-5 h-5" alt="MATIC Token Icon" />
              <span className="text-white py-0.5 rounded-md text-base font-medium">
                {(
                  withdrawableAmountMaticSCv1 + withdrawableAmountMaticSCv2
                ).toLocaleString('en-US')}{' '}
              </span>
            </div>
          </div>
          <div
            onClick={() => handleAccountSelection('SCv1')}
            className="cursor-pointer flex w-full hover:bg-gray-900 bg-opacity-75 justify-between rounded-lg py-1.5 px-3"
          >
            <div className="flex items-center text-white rounded-md text-base font-medium w-44">
              Smart Contract v1
            </div>
            <div className="flex items-center gap-1">
              <img src={maticIcon} className="w-5 h-5" alt="MATIC Token Icon" />
              <span className="text-white py-0.5 rounded-md text-base font-medium">
                {withdrawableAmountMaticSCv1.toLocaleString('en-US')}
              </span>
            </div>
          </div>
          <div
            onClick={() => handleAccountSelection('SCv2')}
            className="cursor-pointer flex w-full hover:bg-gray-900 bg-opacity-75 justify-between rounded-lg py-1.5 px-3"
          >
            <div className="flex items-center text-white rounded-md text-base font-medium w-44">
              Smart Contract v2
            </div>
            <div className="flex items-center gap-1">
              <img src={maticIcon} className="w-5 h-5" alt="MATIC Token Icon" />
              <span className="text-white py-0.5 rounded-md text-base font-medium">
                {withdrawableAmountMaticSCv2.toLocaleString('en-US')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SelectWithdrawAccount
